import React from 'react';
import {CircularProgress, Grid, makeStyles} from '@material-ui/core';
import RhymeCard from './RhymeCard';


const useStyles = makeStyles((theme) => ({
    grid: {
        marginTop: theme.spacing(2),
        // width: '100%',
    },
}));

function RhymeList({rhymes, isLoading}) {
    const classes = useStyles();
    if (typeof rhymes === "undefined" || !('typeToResultMap' in rhymes) || Object.keys(rhymes.typeToResultMap).length === 0) {
        return null;
    }
    const concatenatedList = [
        ...rhymes.typeToResultMap.custom,
        ...rhymes.typeToResultMap.user,
        ...rhymes.typeToResultMap.ending2,
        ...rhymes.typeToResultMap.ending1,
        // ...rhymes.typeToResultMap.v,
    ];
    return (
        <Grid container spacing={2} className={classes.grid}>
            {isLoading ? (
                <Grid item xs={12}>
                    <CircularProgress/>
                </Grid>
            ) : concatenatedList.length ? (
                concatenatedList.map((rhyme, index) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <RhymeCard rhyme={rhyme}/>
                        </Grid>
                    )
                })
            ) : (
                <Grid item xs={12}>
                    <p>No rhymes found.</p>
                </Grid>
            )}
        </Grid>
    );
}

export default RhymeList;
