let languages = [
        {code: "af", name: "Afrikaans"},
        {code: "ar", name: "العربية"},
        {code: "az", name: "Azərbaycanca"},
        {code: "bg", name: "български език"},
        {code: "bs", name: "Bosanski jezik"},
        {code: "ca", name: "Català"},
        {code: "cs", name: "Čeština"},
        {code: "cy", name: "Cymraeg"},
        {code: "da", name: "Dansk"},
        {code: "de", name: "Deutsch"},
        {code: "el", name: "Ελληνικά"},
        {code: "en", name: "English"},
        {code: "eo", name: "Esperanto"},
        {code: "es", name: "Español"},
        {code: "es_419", name: "Español latinoamericano"},
        {code: "et", name: "Eesti keel"},
        {code: "eu", name: "Euskara"},
        {code: "fa", name: "فارسی"},
        {code: "fi", name: "Suomi"},
        {code: "fr", name: "Français"},
        {code: "fr_be", name: "Français de Belgique"},
        {code: "fr_ch", name: "Français de Suisse"},
        {code: "hi", name: "हिन्दी"},
        {code: "hr", name: "Hrvatski"},
        {code: "hu", name: "Magyar"},
        {code: "hy_east", name: "Արևելահայերեն"},
        {code: "hy_west", name: "Արևմտահայերէն"},
        {code: "id", name: "Bahasa Indonesia"},
        {code: "it", name: "Italiano"},
        {code: "ka", name: "ქართული"},
        {code: "kn", name: "ಕನ್ನಡ"},
        {code: "ko", name: "한국어"},
        {code: "la", name: "Latina"},
        {code: "lt", name: "Lietuvių kalba"},
        {code: "lv", name: "Latviešu valoda"},
        {code: "mk", name: "Македонски јазик"},
        {code: "ml", name: "മലയാളം"},
        {code: "ms", name: "Bahasa Melayu"},
        {code: "nl", name: "Nederlands"},
        {code: "no", name: "Norsk"},
        {code: "pl", name: "Polski"},
        {code: "pt", name: "Português"},
        {code: "pt_br", name: "Português (Brasil)"},
        {code: "ro", name: "Română"},
        {code: "ru", name: "Русский"},
        {code: "sk", name: "Slovenčina"},
        {code: "sl", name: "Slovenščina"},
        {code: "sq", name: "Shqip"},
        {code: "sr", name: "Српски"},
        {code: "sv", name: "Svenska"},
        {code: "ta", name: "தமிழ்"},
        {code: "te", name: "తెలుగు"},
        {code: "tr", name: "Türkçe"},
        {code: "ur", name: "اردو"},
        {code: "vi", name: "Tiếng Việt"},
        {code: "zh", name: "中文"}
    ];
    languages.sort((a, b) => a.name.localeCompare(b.name));
export default languages;