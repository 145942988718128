import React from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
  },
  cardContent: {
    textAlign: 'center',
  },
}));

function RhymeCard({ rhyme }) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6">
          {rhyme}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default RhymeCard;
