import React, {useEffect, useState} from 'react';
import {AppBar, Grid, makeStyles, Toolbar} from '@material-ui/core';
import RhymeForm from './RhymeForm';
import RhymeList from './RhymeList';
import {getRhymes} from './api';
import languages from "./constants";
import StoreLinks from "./StoreLinks";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        height: '100px',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'center',
    },
}));


function App() {
    const classes = useStyles();
    const [word, setWord] = useState(get_default_word());
    const [language, setLanguage] = useState(get_default_language());
    const [rhymes, setRhymes] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const handleWordChange = (event) => {
        setWord(event.target.value);
    };
    useEffect(() => {
        console.log('update state', language, word, rhymes, isLoading);
        handleGetRhymes();
    }, [language]);


    const handleLanguageChange = (lang) => {
        setLanguage(lang);

    };

    function updateUrlParams() {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('hl', language);
        urlParams.set('s', word);
        window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
    }

    const handleGetRhymes = () => {
        console.log('handleGetRhymes in App.jsx', word, language)
        if (word === '') {
            return;
        }
        setIsLoading(true);
        getRhymes(word, language)
            .then(response => {
                console.log('Got rhymes for', word, 'in', language, ', response: ', response);
                setRhymes(response);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
        updateUrlParams();
    };


    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar className={classes.toolbar}>
                    <img src="/logo_en_white2.png" alt="Double Rhyme Logo" className={classes.logo}/>
                </Toolbar>
            </AppBar>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={8} md={6}>
                    <StoreLinks/>
                    <RhymeForm
                        word={word}
                        language={language}
                        onWordChange={handleWordChange}
                        onLanguageChange={handleLanguageChange}
                        onGetRhymes={handleGetRhymes}
                        isLoading={isLoading}
                    />
                    <RhymeList
                        rhymes={rhymes}
                        isLoading={isLoading}
                    />
                </Grid>
            </Grid>
        </div>
    );
}


function parseLangCode(rawLangCode) {
    if (!rawLangCode) {
        return undefined;
    }

    const langFirstPart = rawLangCode.split('-')[0];
    for (let i = 0; i < languages.length; i++) {
        if (languages[i].code === langFirstPart) {
            return langFirstPart;
        }
    }
    return undefined;
}

function get_default_language() {
    // get language from the url params
    const urlParams = new URLSearchParams(window.location.search);
    const urlLang = urlParams.get('hl');

    // get language from browser
    const browserLang = navigator.language;

    const langList = [urlLang, browserLang];
    for (let i = 0; i < langList.length; i++) {
        const parsedLangCode = parseLangCode(langList[i]);
        if (parsedLangCode) {
            return parsedLangCode;
        }
    }
    // default to English
    return "en";
}

function get_default_word() {
    // get word from the url params
    const urlParams = new URLSearchParams(window.location.search);
    const urlWord = urlParams.get('s');

    return urlWord || '';
}


export default App;
