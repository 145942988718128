import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    grid: {
        marginTop: theme.spacing(1),
    },
    image: {
        width: '140px',
        // height: 'auto',
    },
}));

const StoreLinks = () => {
    const classes = useStyles();

    return (
        <Grid container justify="center" alignItems="center" className={classes.grid}>
            <Grid item xs={4} container justify="center" alignItems="center">
                <a target="_blank"
                   href="https://play.google.com/store/apps/details?id=com.double_rhyme.hoenickf.doppelreim">
                    <img src="/store-links/android-store-link.png" alt="A" className={classes.image}/>
                </a>
            </Grid>
            <Grid item xs={4} container justify="center" alignItems="center">
                <a target="_blank"
                   href="https://itunes.apple.com/de/app/doppelreim/id1197981487?mt=8">
                    <img src="/store-links/ios-store-link.jpg" alt="B" className={classes.image}/>
                </a>
            </Grid>
        </Grid>
    );
};

export default StoreLinks;
