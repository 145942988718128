import axios from 'axios';

const API_URL = '/search';

export function getRhymes(word, language) {
  console.log('make request to', API_URL, 'with params', { word, language });


  const params = {
    searchterm: word,
    language: language,
    matchType: 'vowel_rhyme_consonantal_ending',
    genre: language === 'en' || language === 'de' ? 'rap' : 'normal'
  };

  return axios.get(API_URL, { params })
    .then(response => response.data)
    .catch(error => console.error(error));
}
