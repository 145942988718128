import React from 'react';
import {FormControl, IconButton, makeStyles, MenuItem, Select, TextField} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import languages from "./constants";

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '12px',
        height: '100%'
    },
    textField: {
        flexGrow: 1,
        marginTop: '0px',
        marginBottom: '0px',
        marginRight: '8px',
    },
    langSelect: {
        minWidth: 120,
        marginRight: '8px',
    },
    button: {
        height: 56
    }
}));

function RhymeForm(props) {
    const classes = useStyles();
    const {
        word,
        language,
        onWordChange,
        onLanguageChange,
        onGetRhymes,
        isLoading
    } = props;



    return (
        <div>
            <div className={classes.form}>
                <TextField
                    label="Enter a word"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={word}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            onGetRhymes();
                        }
                    }}
                    onChange={onWordChange}
                    className={classes.textField}
                />
                <FormControl variant="outlined" className={classes.langSelect}>
                    <Select
                        value={language}
                        onChange={(event) => {
                            onLanguageChange(event.target.value);
                        }}
                    >
                        {languages.map((lang) => (
                            <MenuItem value={lang.code} key={lang.code}>
                                {lang.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>


                <IconButton
                    color="primary"
                    onClick={onGetRhymes}
                    disabled={isLoading}
                    className={classes.button}
                >
                    <SearchIcon/>
                </IconButton>
            </div>
        </div>
    );
}

RhymeForm.propTypes = {
    word: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    onWordChange: PropTypes.func.isRequired,
    onLanguageChange: PropTypes.func.isRequired,
    onGetRhymes: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default RhymeForm;
